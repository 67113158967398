/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { SignUpLoginCredentials } from "./SignUpLoginCredentials";
import { SignUpRestaurantInfo } from "./SignUpRestaurantInfo";
import { SignUpImageUpload } from "./SignUpImageUpload";
import { FormDataProps } from "../restaurant.config";
import { useRestaurantSignupMutation } from "../../../features/services/restaurant/service";
import { useAppDispatch } from "../../../app/hooks";
import { setUser } from "../../../features/slice/restaurant/authSlice";
import { setActivated } from "../../../features/slice/restaurant/authStatusSlice";

const initial_data = {
  email: "",
  password: "",
  name: "",
  address: "",
  phone: "",
  days_open: [],
  image_url: "",
};

export const RestaurantSignUp = () => {
  const navigate = useNavigate();
  const dispatch: any = useAppDispatch();
  const [index, setIndex] = useState(1);
  const [signupLoading, setSignupLoading] = useState(false);
  const [formData, setFormData] = useState<FormDataProps>(initial_data);

  const [
    restaurantSignUp,
    { data: signUpData, isSuccess, isError, error: signUpError },
  ] = useRestaurantSignupMutation();

  // check for changes in the isSuccess and isError properties of tbe restaurantSignup hook and dispatch an action accordingly
  useEffect(() => {
    if (isSuccess) {
      setSignupLoading(false);
      dispatch(
        setUser({
          token: signUpData?.token,
          message: signUpData?.message,
        })
      );
      navigate("/restaurant-portal/account-activation");
      // handleSubscription(signUpData?.token);
      dispatch(setActivated({ isActivated: false }));
    }
    if (isError) {
      setSignupLoading(false);
      if (signUpError && "status" in signUpError) {
        let { data }: any = signUpError;
        toast.error(data?.message);
      }
    }
  }, [isSuccess, isError]);

  // const handleSubscription = async (token: string) => {
  //   try {
  //     const registration = await navigator.serviceWorker.ready;
  //     const applicationServerKey =
  //       "BAfFwyP2-NBeLcQvIUDNw3ZbgwtRxyToLNrd0Oo3_n7L9a43WHXTV-O1hmuy75NM2tb4ABM2RdB4RqnVATW2hf4";

  //     const subscription = await registration.pushManager.subscribe({
  //       userVisibleOnly: true,
  //       applicationServerKey: applicationServerKey,
  //     });

  //     const subscriptionData = {
  //       endpoint: subscription.endpoint,
  //       keys: {
  //         auth: subscription?.toJSON()?.keys?.auth,
  //         p256dh: subscription?.toJSON()?.keys?.p256dh,
  //       },
  //     };

  //     await sendSubscriptionToServer(subscriptionData, token);
  //   } catch (error) {
  //     console.error("Error subscribing to push notifications:", error);
  //   }
  // };

  // const sendSubscriptionToServer = async (subscription: any, token: string) => {
  //   try {
  //     const response = await fetch(
  //       "https://api.peakreachdelivery.com/api/v1/restaurants/subscribe/web-notification",
  //       {
  //         method: "POST",
  //         body: JSON.stringify(subscription),
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     // if (response.status === 200) {
  //     // } else {
  //     // }
  //   } catch (error) {}
  // };

  const signUp = async () => {
    setSignupLoading(true);
    await restaurantSignUp(formData);
  };

  const props = {
    signupLoading,
    index,
    setIndex,
    formData,
    setFormData,
    signUp,
  };

  return (
    <>
      {index === 1 && <SignUpLoginCredentials {...props} />}
      {index === 2 && <SignUpRestaurantInfo {...props} />}
      {index === 3 && <SignUpImageUpload {...props} />}
    </>
  );
};
