/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useEffect, useState } from "react";
import { MdClose, MdSearch } from "react-icons/md";
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBInputGroup,
  MDBRow,
  MDBTabs,
  MDBTabsContent,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import Lottie from "lottie-react";

import { PageLayout } from "../../../components/restaurant-portal/PageLayout";
import { OrderCard } from "../../../components/restaurant-portal/OrderCard";
import { currencyFormatter, forMatDate } from "../../../utils/methods/methods";
import user from "../../../assets/images/landing-page/hero-image.png";
import { OrderDetailsModal } from "../../../components/restaurant-portal/OrderDetailsModal";
import useWindowWidth from "../../../utils/methods/ui-hooks/getWindowWidth";
import ShoppingCart from "../../../assets/shopping_cart.json";
import NoRider from "../../../assets/no-delivery.json";
import NotAllowed from "../../../assets/images/restaurant/dashboard/not-allowed.svg";
import {
  useLazyGetAllOrdersQuery,
  useGetAvailableRidersMutation,
  useLazyGetRestaurantProfileQuery,
  useDeleteMealMutation,
  useDeclineMealOrderMutation,
  useAcceptMealOrderMutation,
  useLazyGetRiderDetailsQuery,
} from "../../../features/services/restaurant/service";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { IoReload } from "react-icons/io5";
import Loader from "../../../components/loader";
import { toast } from "react-toastify";
import { capitalize } from "@mui/material";

interface D {
  order: OrderProps[];
  status: string;
  note: string;
  id: string;
}
interface OrderProps {
  id: string;
  name: string;
  image_url: string;
  price: number;
  qty: number;
}
interface AllOrderProps {
  _id: string;
  time_of_order: string;
  order_amount: number;
  status: string;
  order_list: OrderProps[];
  customer_note: string;
}

// STATIC DATA
const tab_links = [
  { id: 0, name: "All", value: "tab0", queryParam: "" },
  { id: 1, name: "Pending", value: "tab1", queryParam: "pending" },
  { id: 2, name: "Confirmed", value: "tab2", queryParam: "confirmed" },
  { id: 3, name: "Delivered", value: "tab3", queryParam: "delivered" },
  { id: 4, name: "Declined", value: "tab4", queryParam: "declined" },
  { id: 5, name: "Enroute", value: "tab5", queryParam: "enroute" },
  { id: 6, name: "Cancelled", value: "tab6", queryParam: "cancelled" },
];

const driver = {
  name: "Jude Ogbemudia",
  phone: "+23481678944",
  photo: user,
};

export const OrderManager = () => {
  const [tabActive, setTabActive] = useState("tab0");
  const [displayItem, setDisplayItem] = useState<any>({
    order: [],
    status: "",
    note: "",

    id: "",
  });
  const [orderStatus, setOrderStatus] = useState<string | undefined>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [accept_loading, setAcceptLoading] = useState<boolean>(false);
  const [decline_loading, setDeclineLoading] = useState<boolean>(false);
  const [retryLoading, setRetryLoading] = useState<boolean>(false);
  const [riderModal, setRiderModal] = useState<boolean>(false);
  const [agentId, setAgentId] = useState("");

  const screenWidth = useWindowWidth(); // custom hook

  // To query orders
  // const [
  //   getAllOrders,
  //   { orders, loading_orders, orders_error, refetching_orders },
  // ] = useLazyGetAllOrdersQuery({
  //   selectFromResult: ({ data, isLoading, error, isFetching }) => ({
  //     orders: data,
  //     loading_orders: isLoading,
  //     orders_error: error,
  //     refetching_orders: isFetching,
  //   }),
  // });
  const [
    getAllOrders,
    { data: orders, isLoading: loading_orders, error: orders_error },
  ] = useLazyGetAllOrdersQuery();

  const [
    getAvailableRiders,
    {
      data: available_riders_data,
      isSuccess: accept_order_success,
      isError: accept_order_error,
    },
  ] = useGetAvailableRidersMutation();

  const [
    declineMealOrderMutation,
    {
      data: decline_order_data,
      isSuccess: decline_order_success,
      error: decline_order_error,
    },
  ] = useDeclineMealOrderMutation();
  // const [acceptMealOrder ]
  const [
    acceptMealOrder,
    {
      data: acceptMealStatus,
      isLoading: acceptingOrder,
      error: errorAcceptingOrder,
    },
  ] = useAcceptMealOrderMutation();
  const [getRestaurantProfile, { data: profileData, error }] =
    useLazyGetRestaurantProfileQuery();

  const [
    getRiderDetails,
    {
      data: riderDetails,
      isLoading: gettingRiderDetails,
      error: errorGettingRiderDetails,
    },
  ] = useLazyGetRiderDetailsQuery();

  const handleTabClick = (value: string, query?: string) => {
    if (value === tabActive) {
      return;
    }
    setTabActive(value);
    setOrderStatus(query);
  };

  const handleItemDisplay = (
    val1: any,
    val2: string,
    val3: string,
    val4: any
  ) => {
    setDisplayItem({ order: [val1], status: val2, note: val3, id: val4 });
  };
  const handleAcceptOrder = (e: any) => {
    e.preventDefault();
    setAcceptLoading(true);
    const order_id = displayItem?.id;

    acceptMealOrder(order_id);

    // This is for testing purposes only
    // getAvailableRiders({ longitude: 7.2512275, latitude: 6.6512275, order_id });
    try {
    } catch (err) {}
  };

  const rejectOrder = (e: any) => {
    e.preventDefault();
    setDeclineLoading(true);
    const order_id = displayItem?.id;

    try {
      declineMealOrderMutation(order_id);
    } catch (err) {}
  };
  // const handleRetry = (e: any) => {
  //   const newDistance = distance + 100;
  //   e.preventDefault();
  //   setRetryLoading(true)
  //   const longitude = profileData?.address?.longitude;
  //   const latitude = profileData?.address?.latitude;
  //   const max_distance = distance;
  //   const data = { longitude, latitude, max_distance }
  //   try{
  //     getAvailableRiders(data);
  //   }catch(err){
  //   }
  //   setDistance(newDistance);
  // }

  useEffect(() => {
    if (acceptMealStatus) {
      toast.success(acceptMealStatus?.message);
      setShowModal(false);
      const longitude = profileData?.address?.longitude;
      const latitude = profileData?.address?.latitude;
      const order_id = displayItem?.id;
      const data = { longitude, latitude, order_id };
      // getAvailableRiders(data);
    }
    if (errorAcceptingOrder) {
      toast.error("Error accepting order");
      setShowModal(false);
    }
  }, [acceptMealStatus, errorAcceptingOrder]);
  useEffect(() => {
    getRestaurantProfile();
  }, []);

  //New order fetching function to prevent restaurants having to reload before they can see new orders
  useEffect(() => {
    // Function to fetch orders based on order status
    const fetchOrders = () => {
      orderStatus !== ""
        ? getAllOrders({ page: 1, order_status: orderStatus })
        : getAllOrders({ page: 1 });
    };

    // Initial call to fetch orders
    fetchOrders();

    // Set interval to fetch orders every 10 seconds
    const interval = setInterval(fetchOrders, 5000);

    // Cleanup function to clear interval on component unmount
    return () => clearInterval(interval);
  }, [orderStatus, getAllOrders]);

  // useEffect(() => {
  //   // Conditional this because when the orderStatus === " " the enpoint
  //   // returns an empty array of orders instead of all orders
  //   orderStatus !== ""
  //     ? getAllOrders({ page: 1, order_status: orderStatus })
  //     : getAllOrders({ page: 1 });
  //   // getAllOrders({ page: 1 });
  // }, [orderStatus, orders]);

  // useEffect(() => {
  //   if (accept_order_success) {
  //     setAcceptLoading(false);
  //     setRetryLoading(false);
  //     setShowModal(false);
  //     toast.success(available_riders_data.message);
  //     // setRiderModal(true);
  //   }

  //   if (accept_order_error) {
  //     setAcceptLoading(false);
  //     setRetryLoading(false);
  //     const error: any = accept_order_error;
  //     toast.error(error?.error);
  //   }
  // }, [accept_order_success, accept_order_error]);

  // useEffect(() => {
  //   if (decline_order_success) {
  //     setDeclineLoading(false);
  //     setShowModal(false);
  //     toast.success(decline_order_data.message);
  //   }

  //   if (decline_order_error) {
  //     setDeclineLoading(false);
  //     setRetryLoading(false);
  //     const error: any = decline_order_error || decline_order_error[0];
  //     toast.error("error");
  //   }
  // }, [decline_order_success, decline_order_error]);

  // }, [orderStatus]);
  console.log(orders);
  useEffect(() => {
    if (accept_order_success) {
      setAcceptLoading(false);
      setRetryLoading(false);
      setShowModal(false);
      toast.success(available_riders_data.message);
    }

    if (accept_order_error) {
      setAcceptLoading(false);
      setRetryLoading(false);
      const error: any = accept_order_error;
      toast.error(error?.error);
      setShowModal(false);
    }
  }, [accept_order_success, accept_order_error]);

  useEffect(() => {
    if (decline_order_success) {
      setDeclineLoading(false);
      setShowModal(false);
      toast.success(decline_order_data.message);
    }

    if (decline_order_error) {
      setDeclineLoading(false);
      setRetryLoading(false);
      const error: any = decline_order_error || decline_order_error[0];
      toast.error(error?.data?.error);
    }
  }, [decline_order_success, decline_order_error]);
  useEffect(() => {
    getRiderDetails({ agent_id: agentId });
  }, [agentId]);

  // console.log(orders);
  return (
    <>
      <PageLayout>
        <MDBContainer
          fluid={true}
          breakpoint="md"
          className="order-manager-container"
        >
          <MDBRow>
            <MDBCol size="12" md="4" className="col-1">
              <h1>Order list</h1>
              <div>
                <MDBInputGroup className="search-group">
                  <MDBBtn className="search-btn">
                    <MdSearch />
                  </MDBBtn>
                  <input
                    type="search"
                    placeholder="Search"
                    className="search-input"
                  />
                </MDBInputGroup>
              </div>

              <div>
                <MDBTabs className="mb-3">
                  {tab_links?.map((link, id) => (
                    <MDBTabsItem key={id}>
                      <MDBTabsLink
                        className="tab-link"
                        onClick={() =>
                          handleTabClick(link.value, link.queryParam)
                        }
                        active={tabActive === link.value}
                      >
                        {link.name}
                      </MDBTabsLink>
                    </MDBTabsItem>
                  ))}
                </MDBTabs>

                <MDBTabsContent>
                  <MDBTabsPane show={orders}>
                    {/* There should be a skeleton for this loader && blur component on refetching_orders */}

                    {loading_orders ? (
                      <SkeletonTheme highlightColor="#f0f0f0">
                        <div style={{ display: "grid", alignItems: "center" }}>
                          <Skeleton width={200} height={70} />
                          <Skeleton width={200} height={70} />
                          <Skeleton width={200} height={70} />
                        </div>
                      </SkeletonTheme>
                    ) : (
                      <>
                        {orders && orders?.length > 0 ? (
                          <ul className="all-orders">
                            {orders?.map((order: any, id: number) => (
                              <OrderCard
                                key={id}
                                id={order?._id}
                                time={order?.created_at}
                                amount={order?.items[0]?.meal.price}
                                borderColor={
                                  order?.order_status === "delivered" ||
                                  order?.order_status === "arrived"
                                    ? "#1A9C2C"
                                    : order?.order_status === "confirmed" ||
                                      order?.order_status === "preparing"
                                    ? "#957001"
                                    : order?.order_status === "pending"
                                    ? "#FFCD3C"
                                    : order?.order_status === "declined"
                                    ? "#B00020"
                                    : order?.order_status === "enroute"
                                    ? "#61BDFF"
                                    : "#000"
                                }
                                headerClass={"order-header"}
                                handleClick={() => {
                                  setShowModal(true);
                                  setAgentId(order?.agent);
                                  handleItemDisplay(
                                    order?.items,
                                    order?.order_status,
                                    order?.note,
                                    order?._id
                                  );
                                }}
                              />
                            ))}
                          </ul>
                        ) : (
                          <div>
                            {orderStatus === ""
                              ? "You have no order history"
                              : `You have no ${orderStatus} order`}
                          </div>
                        )}
                      </>
                    )}
                  </MDBTabsPane>
                </MDBTabsContent>
              </div>
            </MDBCol>

            <MDBCol size="12" md="8" className="bg-white col-2">
              <div className="order-details">
                <h2>Order details</h2>
                {displayItem?.order.length > 0 ? (
                  <div className="order-details-container">
                    <ul>
                      {displayItem?.order[0]?.map((item: any, id: number) => (
                        <li key={id}>
                          <div className="item-name">
                            <img
                              src={item?.meal?.image_url}
                              alt={item?.meal?.name}
                            />
                            <p>{item?.meal?.name}</p>
                          </div>
                          <div>x{item?.qty}</div>
                          <div className="item-price">
                            {item?.meal?.price?.currency_code === "NGN"
                              ? "₦"
                              : "USD"}
                            {currencyFormatter(item?.meal?.price?.amount)}
                          </div>
                        </li>
                      ))}
                    </ul>
                    {displayItem?.note && (
                      <div className="customer-note">
                        <h3>CUSTOMER NOTE</h3>
                        <p>{displayItem?.note}</p>
                      </div>
                    )}
                    {displayItem.status === "pending" && (
                      <div className="btn-container">
                        <button
                          className="btn accept-btn"
                          onClick={handleAcceptOrder}
                        >
                          {acceptingOrder ? "ACCEPTING..." : "ACCEPT ORDER"}
                        </button>
                        <button
                          className="btn decline-btn"
                          onClick={rejectOrder}
                        >
                          {decline_loading ? "DECLINING..." : "DECLINE ORDER"}
                        </button>
                      </div>
                    )}
                    {riderDetails && agentId !== "000000000000000000000000" && (
                      <>
                        <div className="rider-info-container">
                          <h3>RIDER INFORMATION</h3>
                          <div className="rider-info">
                            <div className="photo-container">
                              <img
                                src={riderDetails?.image_url}
                                alt={riderDetails?.first_name}
                                height="80px"
                                width="80px"
                              />
                            </div>
                            <div>
                              <h4 style={{ textTransform: "capitalize" }}>
                                {riderDetails?.first_name}{" "}
                                {riderDetails?.last_name}
                              </h4>
                              <p>{riderDetails?.phone}</p>
                            </div>
                          </div>
                        </div>
                        <div className="btn-container">
                          {/* <button className="btn accept-btn">
                            MARK AS DELIVERED
                          </button> */}
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <div>
                    <div className="shopping-cart-gif">
                      <Lottie animationData={ShoppingCart} loop={false} />
                    </div>
                    <div className="shopping-cart-text text-center">
                      <h4>Order details will be displayed here.</h4>
                    </div>
                  </div>
                )}
              </div>
            </MDBCol>
          </MDBRow>

          {/* {showModal && <p>works</p>} */}
        </MDBContainer>
      </PageLayout>

      {screenWidth <= 799 && showModal && displayItem.order.length > 0 && (
        <OrderDetailsModal
          displayItem={displayItem.order[0]}
          decline_loading={decline_loading}
          accept_loading={accept_loading}
          note={displayItem.note}
          status={displayItem.status}
          acceptOrder={handleAcceptOrder}
          rejectOrder={rejectOrder}
          driver={driver}
          showModal={showModal}
          setModalDisplay={setShowModal}
        />
      )}

      {riderModal && (
        <div className="rider-modal-container">
          <div className="rider-display">
            <div className="header">
              <p>Available Riders</p>
              <div className="close-btn" onClick={() => setRiderModal(false)}>
                <MdClose />
              </div>
            </div>
            <div className="main">
              {available_riders_data?.length > 0 ? (
                <ul className="not-empty">
                  {available_riders_data.map((item: any, index: number) => (
                    <li key={index}></li> // display all avalaible riders
                  ))}
                </ul>
              ) : (
                <div className="empty">
                  <div className="lottie">
                    {screenWidth >= 800 && (
                      <img
                        src={NotAllowed}
                        alt="not-allowed"
                        height={100}
                        width={100}
                        style={{
                          position: "absolute",
                          left: "6.7rem",
                          top: "4.5rem",
                        }}
                      />
                    )}
                    <Lottie animationData={NoRider} />
                  </div>
                  <h4>No available rider</h4>
                  {/* <div className="retry-btn">
                    <div>
                      { retryLoading ?
                      <Loader
                        height="27px"
                        width="27px"
                        borderTopColor='#FFAE0A'
                        borderTopWidth='5px'
                        borderWidth='5px' />
                      :
                      <button onClick={handleRetry}>
                        <IoReload />
                        <span>Retry</span> 
                      </button>
                      }
                    </div>
                  </div> */}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
